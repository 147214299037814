import axios from "axios";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";
// import { notificationStore } from "../main";
// Define the URL of your Firebase Cloud Function
const firebaseFunctionURL =
  "https://us-central1-law-office-flutterflow.cloudfunctions.net/app/notifications";

export function pushNotification(ID, body, title) {
  return new Promise((resolve, reject) => {
    console.log("id", ID, "body", body, "title", title);
    axios
      .post(`${firebaseFunctionURL}/push/`, {
        user: ID,
        body,
        title,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);

        reject(err);
      });
  });
}
export function create(clientID, agentID, title, body, type, typeID) {
  return new Promise((resolve, reject) => {
    console.log(clientID, agentID, type, typeID);
    axios
      .post(`${firebaseFunctionURL}/`, {
        user: clientID,
        type,
        typeID,
        agent: agentID.uid,
        clientID,
        title,
        body,
        notificationFor: "app",
      })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);

        reject(err);
      });
  });
}
export function GetUnseen(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${firebaseFunctionURL}/unseen/${id}?notificationFor=web`)
      .then((res) => {
        const sortedData = res.data.unseen
          .map((object) => {
            return {
              ...object, // Copy all existing properties
              createdAt: moment(
                nanosecondsToISO8601(object.created_at._seconds)
              ).calendar(), // Add the new attribute
              created_at: moment(
                nanosecondsToISO8601(object.created_at._seconds)
              ), // Keep the original moment object for sorting
            };
          })
          .sort((a, b) => b.created_at - a.created_at); // Sort by created_at in descending order

        resolve(sortedData);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);
        reject(err);
      });
  });
}
export function MakeSeen(id, userID) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${firebaseFunctionURL}/seen`, {
        notification: id,
        seenBy: userID,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);

        reject(err);
      });
  });
}
export function listen() {
  // Create a Firestore reference
  const db = firebase.firestore();

  // Reference to the Firestore collection you want to listen to
  const collectionRef = db.collection("notifications");
  // eslint-disable-next-line no-unused-vars
  const unsubscribe = collectionRef.onSnapshot((querySnapshot) => {
    // This callback will be triggered when changes occur in the collection
    console.log("start listening");
    querySnapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        // Handle added documents here
        const addedDoc = change.doc.data();
        addedDoc.ID = change.doc.id;
        //notificationStore.dispatch("notifications/setNotifications", addedDoc);
        //console.log(addedDoc);
        // console.log("Document added:", addedDoc);
        return { DocumentAdded: addedDoc };
        // Perform your desired actions when a document is added
      }
    });
  });
}

export function nanosecondsToISO8601(seconds) {
  const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
  const date = new Date(milliseconds);
  return date;
}
