<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ year }} © Ylaw.</div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data(){
    return {
      year:''
    }
  },
  created() {
    const nowDate = new Date();
    this.year = nowDate.getFullYear()
  }
}
</script>
